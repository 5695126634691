import Group from 'components/utils/Group'
import { EXPERIENCE_MINIMUM_DISCOUNT_PERCENTAGE_TO_SHOW } from 'constants/experience'
import React from 'react'
import TextButton from 'components/Luxkit/Button/TextButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'

interface Props {
  experience: App.ExperienceBookingView
}

function ExperienceOfferPriceDetails({ experience }: Props) {
  return (
    <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="end">
      <Group direction="vertical" verticalAlign="end">
        <PriceRowPriceCaption>From</PriceRowPriceCaption>
        <PriceRowPrice
          size="L"
          price={experience.price}
          saleUnit={experience.ticketUnitLabel ?? 'total'}
        />
        {experience.discount >= EXPERIENCE_MINIMUM_DISCOUNT_PERCENTAGE_TO_SHOW && <PriceRowValueDiscountWithCaption
          size="L"
          originalValue={Math.ceil(experience.grossPrice)}
          discountPercentage={experience.discount}
        />}
      </Group>
      <CSSBreakpoint min="desktop">
        <TextButton kind="primary" nonInteractable>
          View
        </TextButton>
      </CSSBreakpoint>
    </Group>
  )
}

export default ExperienceOfferPriceDetails
