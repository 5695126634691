import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useMostPopulatedSearchExperienceCategories, { CategoryData } from 'components/Experiences/hooks/useMostPopulatedSearchExperienceCategories'
import OfferCarouselHeader from '../common/OfferCarouselHeader'
import LayoutContainer from 'components/Common/LayoutContainer'
import { rem } from 'polished'
import styled from 'styled-components'
import { forceElementToFirstPosition, take } from 'lib/array/arrayUtils'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import FullWidthCarouselWithAnalytics from 'components/WithAnalytics/Carousel/FullWidthCarouselWithAnalytics'
import OfferCarouselLookingForSomethingElse from 'home/components/LookingForSomethingElse/OfferCarouselLookingForSomethingElse'
import { LOOKING_FOR_SOMETHING_ELSE_CONTENT } from 'home/components/LookingForSomethingElse/constants'
import ExperienceOfferCard from 'components/OfferList/OfferCards/ExperienceOfferCard'
import CarouselCardLoadingSkeleton from 'components/OfferList/OfferCards/CarouselCardLoadingSkeleton'
import useExperienceSearchList from 'hooks/Experiences/useExperienceSearchList'
import { useInView } from 'react-intersection-observer'
import { CAROUSEL_IN_VIEW_PARAMS } from 'home/constants'
import qs from 'qs'
import LoaderPlayStateContext from 'contexts/LoaderPlayStateContext'
import OfferCardCarouselSectionFilters from 'components/Common/OfferCardCarousel/OfferCardCarouselSectionFilters'

const LUX_EXCLUSIVE_CATEGORY_ID = 40

const ExperienceOfferCarouselContainer = styled.div`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};
`

const listLimit = 8

interface Props {
  place?: App.Place;
  experienceHeading: React.ReactNode;
  addImpression?: boolean;
  campaigns?: Array<string>;
  showFilters?: boolean;
}

function ExperienceOfferCarousel(props: Props) {
  const { experienceHeading, place, campaigns, showFilters = true } = props
  const [selectedCategory, setSelectedCategory] = useState<App.ExperienceItemCategory>()
  const [mostPopulatedCategories] = useMostPopulatedSearchExperienceCategories(place)

  const categories: Array<CategoryData> = useMemo(() => {
    const luxExclusiveCategory = mostPopulatedCategories.find(category => category.parent.id === LUX_EXCLUSIVE_CATEGORY_ID)
    return forceElementToFirstPosition<CategoryData>(mostPopulatedCategories, luxExclusiveCategory)
  }, [mostPopulatedCategories])

  const [inViewRef, inView] = useInView(CAROUSEL_IN_VIEW_PARAMS)

  const list = useExperienceSearchList({
    placeId: place?.id,
    enabled: !!selectedCategory,
    campaigns,
    categoryCodes: selectedCategory ? [selectedCategory.id] : undefined,
  })

  const toggleFilter = useCallback((name: string) => {
    const newCategory = (categories.find(category => category.parent.name === name) || categories[0]).parent
    setSelectedCategory(newCategory)
  }, [categories])

  useEffect(() => {
    if (categories.length > 0 && showFilters) {
      setSelectedCategory(categories[0].parent)
    }
  }, [categories, showFilters])

  const filterOptions = useMemo(() => take(categories, 5).map(category => ({
    value: category.parent.name,
    label: category.parent.name,
  })), [categories])

  const to = useMemo(() => {
    if (selectedCategory?.id && place?.id && place?.name) {
      const query = {
        destinationId: place.id,
        destinationName: place.name,
        categories: selectedCategory.id.toString(),
      }
      return `/search/experiences?${qs.stringify(query)}`
    }
    return LOOKING_FOR_SOMETHING_ELSE_CONTENT.experiences.to
  }, [place, selectedCategory?.id])

  return (
    <ExperienceOfferCarouselContainer>
      <VerticalSpacer gap={20}>
        <LayoutContainer>
          <VerticalSpacer gap={20}>
            <OfferCarouselHeader allTo="/experiences">
              {experienceHeading}
            </OfferCarouselHeader>
            {showFilters && <OfferCardCarouselSectionFilters
              options={filterOptions}
              value={selectedCategory?.name}
              onChange={toggleFilter}
            />}
          </VerticalSpacer>
        </LayoutContainer>
        <FullWidthCarouselWithAnalytics
          snap="start"
          itemsPerArrow={3}
          ref={inViewRef}
        >
          {list.fetching && <LoaderPlayStateContext.Provider value={inView ? 'running' : 'paused'}>
            <CarouselCardLoadingSkeleton size="medium" />
            <CarouselCardLoadingSkeleton size="medium" />
            <CarouselCardLoadingSkeleton size="medium" />
            <CarouselCardLoadingSkeleton size="medium" />
          </LoaderPlayStateContext.Provider>}
          {take(list.ids, listLimit).map((id, index) =>
            <ExperienceOfferCard
              key={id}
              id={id}
              index={index}
              lazyLoad
              size="medium"
            />,
          )}
          <OfferCarouselLookingForSomethingElse to={to} />
        </FullWidthCarouselWithAnalytics>
      </VerticalSpacer>
    </ExperienceOfferCarouselContainer>
  )
}

export default ExperienceOfferCarousel
